<template>
    <tr>
        <td v-if="columns.includes('transaction_id')">
            <div v-if="!hide_column_values.includes('transaction_id')" class="flexo">
                <span v-tooltip="$t('analysis:transaction:details')">
                    <a :href="'/market/transaction/'+item.transaction_id+'/details'" class="open-modal" @click="$emit('close-fullscreen')">
                        #{{item.transaction_id}}
                    </a>
                </span>
                <a :href="'/market/transaction/'+item.transaction_id+'/details'" class="open-modal text-white" @click="$emit('close-fullscreen')">
                    <span
                        v-if="item.transaction_volume > 1"
                        class="label label-rouded label-info"
                        v-tooltip="'Posel vsebuje več nepremičnin'"
                        v-on:click.prevent="$emit('labelclicked', item.transaction_id)"
                    >
                        {{item.transaction_volume}}
                        <!-- <i class="fa"
                            v-bind:class="row_expanded ? 'fa-caret-up' : 'fa-caret-down'">
                        </i> -->
                    </span>
                </a>
                <div>
                    {{item.transaction_date}}
                </div>
            </div>
        </td>

        <td v-if="columns.includes('address')" style="max-width: 200px;">
            <div class="re-key" v-tooltip="$t('real_estate:details')">
                <template v-if="getCountryPlugin('COMPARABLES_RE_KEY_LINKS_ENABLED')">
                    <a :href="'/amas/real_estate/'+item.re_key+'/details'" class="open-modal" @click="$emit('close-fullscreen')">
                        {{item.re_key}}
                    </a>
                </template>
                <template v-else>
                        {{item.re_key}}
                </template>
            </div>
            <div>
                {{item.get_component_type_display}}
                <span v-if="item.property_type_mismatch">
                  <i class="fa fa-exclamation-triangle text-danger"
                        data-toggle="tooltip"
                        :data-original-title="$t('analysis:warning:property_type_mismatch')"
                  ></i>
                </span>
            </div>
            <span v-if="item.is_unit">
                {{item.address}}
            </span>
            <span v-else>
                <span v-tooltip="$t('real_estate:parcel:muncipality:kataster')" class="cursor-help">
                    {{item.cadastral_municipality|capitalize}},
                </span>
                <span v-tooltip="$t('real_estate:municipality')" class="cursor-help">
                    {{item.municipality|capitalize}}
                </span>
            </span>
        </td>

        <!-- <td v-if="columns.includes('transaction_date')">
            {{item.transaction_date}}
        </td> -->

        <td v-if="columns.includes('component_size')">
            <div style="font-weight: 400">
                <span>
                    {{item.component_size ||'/'|formatSize(item.is_unit ? 2 : 0)}}
                    <span
                        v-if="item.sale_share && item.sale_share != '1/1' && item.sale_share != '1'"
                        class="label label-rouded label-info"
                        v-tooltip="$t('analysis:ct:share_of_sales')"
                    >
                        {{item.sale_share}}
                    </span>
                </span>
            </div>
            <div style="font-size:smaller">
                <span v-tooltip="$t('analysis:ct:revised_size')" class="cursor-help">
                    {{$t("analysis:ct:revised_size_short")}}:  {{item.transaction_revised_size||'/'|formatSize(item.is_unit ? 2 : 0)}}
                </span>
                |
                <span v-tooltip="$t('analysis:ct:total_parcel_area_in_transaction')" class="cursor-help">
                    {{$t("analysis:ct:parcel_short")}}:  {{item.transaction_sum_parcel_sizes||'/'|formatSize(item.is_unit ? 2 : 0)}}
                </span>
                |
                <span v-tooltip="$t('analysis:ct:total_unit_area_in_transaction')" class="cursor-help">
                    {{$t("analysis:ct:unit_short")}}: {{item.transaction_sum_unit_sizes||'/'|formatSize(item.is_unit ? 2 : 0)}}
                </span>
            </div>
        </td>

        <td v-if="columns.includes('sizes')">
            <div style="font-weight: 400">
                <span v-tooltip="$t('analysis:ct:usable_net_area_ratio')" class="cursor-help">
                    <span v-if="item.useful_net_size_ratio">{{item.useful_net_size_ratio|formatNumber}}</span>
                    <span v-else>/</span>
                </span>
            </div>
            <div style="font-size:smaller">
                <span v-tooltip="$t('analysis:ct:net_floor_area')" class="cursor-help">
                    {{$t("analysis:ct:net_area_short")}}: {{item.REN_net_size||'/'|formatSize(item.is_unit ? 2 : 0)}}
                </span>
                |
                <span v-tooltip="$t('analysis:ct:useful_area')" class="cursor-help">
                    {{$t("analysis:ct:useful_area_short")}}: {{item.REN_useful_size||'/'|formatSize(item.is_unit ? 2 : 0)}}
                </span>
            </div>
            <div>
                <span>
                    <unit-size-estimate-indicator
                        v-if='item.unit'
                        :unit="item.unit"            
                    >
                    </unit-size-estimate-indicator>  
                </span>
            
            </div>
                                         
        </td>

        <td v-if="columns.includes('building_year_built')">
            <div style="font-weight: 400">
                {{item.building_year_built || '/'}}
            </div>
            <div style="font-size:smaller" v-tooltip="$t('real_estate:renovations')" v-if="item.unit">
                <renovations-block
                    :unit="item.unit"
                />
            </div>
        </td>

        <td v-if="columns.includes('transaction_amount_gross')">
            <div class="text-info font-medium" style="font-weight: 400">
                <span v-tooltip="$t('analysis:ct:transaction_value_contract_price')" class="cursor-help">
                    {{item.transaction_amount_gross|formatCurrency(0)}}
                </span>
            </div>
            <div v-if="item.transaction_tax || item.component_tax">
                <small>{{$t("general:with")}} {{$t("general:vat")}}</small>
            </div>
        </td>

        <td v-if="columns.includes('transaction_amount_m2')">
            <span v-if="item.transaction_amount_m2">
                <span class="font-medium text-primary">
                    {{item.transaction_amount_m2|formatCurrencym2(0)}}
                </span>
                <div v-if="item.transaction_tax || item.component_tax">
                    <small>{{$t("general:with")}} {{$t("general:vat")}}</small>
                </div>
            </span>
            <span v-else-if="item.estimated_amount_m2" class="font-medium text-secondary">
                {{item.estimated_amount_m2|formatCurrencym2(0)}}
            </span>
            <span v-else class="font-medium text-secondary">
                /
            </span>
        </td>

        <td v-if="columns.includes('match_score')">
            <match-score-label
                :score="item.score"
            />
        </td>

        <td v-if="columns.includes('options')">
            <a href="#"
                v-on:click.prevent.stop="$emit('comparable-clicked', {item, selection: 'comparable'})"
                v-tooltip="getCheckboxIconTooltip(item, true)"
            >
                <i class="fa" :class="getCheckboxIconClass(item, true)"></i>
            </a>
        </td>

        <td v-if="columns.includes('options')">
            <a href="#"
                v-on:click.prevent.stop="$emit('comparable-clicked', {item, selection: 'wide_set'})"
                v-tooltip="getCheckboxIconTooltip(item, false)"
            >
                <i class="fa" :class="getCheckboxIconClass(item, false)"></i>
            </a>
        </td>

    </tr>
</template>

<script>
import MatchScoreLabel from "./MatchScoreLabel"
import _UnitRenovationsBlock from "../../adjustments/components/_UnitRenovationsBlock.vue";

import UnitSizeEstimateIndicator from '@apps/real_estate/vue/components/UnitSizeEstimateIndicator.vue';

import countrymixin from '@/mixins/countrymixin';

export default {
    mixins: [ countrymixin ],
    components: {
        "renovations-block": _UnitRenovationsBlock,
        MatchScoreLabel,
        UnitSizeEstimateIndicator,
    },
    props:  {
        item: Object,
        columns: {
            type: Array,
            default: () => []
        },
        hide_column_values: {
            type: Array,
            default: () => []
        },
        row_expanded: {
            type: Boolean,
            default: false
        },
    },
    methods: {
        _isInProgress: function(item) {
            return this.$store.state.failedTransactionItemsIds.includes(item.id);
        },
        _isFailed: function(item) {
            return this.$store.state.inprogressTransactionItemsIds.includes(item.id)
        },
        getCheckboxIconClass: function(item, comparable) {
            if (this._isInProgress(item)) {
                return "fa fa-exclamation-circle";
            }
            if (this._isFailed(item)) {
                return "fa fa-spinner fa-spin";
            }

            if ( item.comparable===true ) {
                return "fa-check-square-o";
            } else if ( item.comparable===false && comparable===false ) {
                return "fa-check-square-o";
            } else {
                return "fa-square-o";
            }
        },
        getCheckboxIconTooltip: function(item, comparable) {
            if (this._isInProgress(item)) {
                return "";
            }
            if (this._isFailed(item)) {
                return this.$t("general:error2");
            }

            if ( comparable===true ) {
                return this.$t("analysis:ct:add_or_remove_from_comparable");
            } else {
                return this.$t("analysis:ct:add_or_remove_from_wide_set");
            }
        }
    }
}
</script>
